import { Injectable } from "@angular/core";
import { AppSettings, Settings } from "../../app.settings";

@Injectable()

export class ConvertCurrencyService {
    public settings: Settings;

    constructor(public _appSettings:AppSettings) {
        this.settings = this._appSettings.settings; 
    }

    convertCurrency(value){
          let newData = '';
          if(this.settings.selectedCompDetails?.branch?.country == 'India'){
              if(value >= 1000000000){
                  newData = (value/1000000000).toFixed(2) + " Ar"
              }else if(value >= 10000000){
                  newData = (value/10000000).toFixed(2) + " Cr"
              }else if(value >= 100000){
                  newData = (value/100000).toFixed(2) + " L"
              }else if(value >= 1000){
                  newData = (value/1000).toFixed(2) + " K"
              }else{
                  newData = value;
              }
          }else{
            if(value >= 1000000000){
                newData = (value/1000000000).toFixed(2) + " B"
            }else if(value >= 1000000){
                newData = (value/1000000).toFixed(2) + " M"
            }else if(value >= 1000){
                newData = (value/1000).toFixed(2) + " K"
            }else{
                newData = value;
            }
          }
        return newData;
    }
}
