import {Component, ViewEncapsulation, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../../../../pages/data.service';
import { params } from '../../../../../pages/params';
import { urls } from '../../../../../pages/urls';
import {environment} from "../../../../../../environments/environment";


@Component({
  selector: 'ba-cart-count',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'baCartCount.html',
})
export class BaCartCount implements OnInit, OnChanges {
    cartCount: any = 0;
    cartRedirection: any = true;
    @Input() plantId = '';

    constructor(private _router: Router, private _service: DataService){

    }
    ngOnInit() {
        this.fetchCart();
        this.initializeSubscribers();
    }

    ngOnChanges(changes: SimpleChanges){
        this.plantId = changes.plantId.currentValue;
        if (parseInt(this.plantId, 10) === -1 || this._service.getBuyerPlantId() === '-1') {
            this.cartRedirection = false;
        } else {
            this.cartRedirection = true;
        }
    }

    initializeSubscribers() {
        this._service.cartSubject.subscribe((count) => {
            this.cartCount = count;
        });
    }
    fetchCart() {
        this._service.callRestful('GET', environment.URLS.PROCUREMENT_URL + urls.CART.FETCH_CART + urls.CART.PO).subscribe(
            (rawData) =>
            {
                const data = rawData;
                if (data['status_code'] === 1) {
                    this.cartCount = data['cart'].count;
                } else {
                    alert(data['error_msg']);
                }
            }
        );
    }
    public goToCart() {
        if (this.cartRedirection) {
            if (this.cartCount > 0) {
                this._router.navigateByUrl('/pages/orders/po/cart');
            } else {
                this._service.showMessage('error', 'Cart is Empty!');
            }
        } else {
            this._service.showMessage('error', 'Please select a specific plant to open cart');
        }
    }

}
