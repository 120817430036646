<div class="mogb_innersidebar">
    <ul class="mogb_sidemenulist">
        <menu-item [menuItem]="item" (itemHover)="hoverItem($event)" [itemNumber]="i" (toggleSubMenu)="toggleSubMenu($event)"
        *ngFor="let item of menuItems;let i = index"></menu-item>
        <li class="download-app-sidebar">
            <div class="box">
                <h5>Download Moglix Enterprise<br> mobile app</h5>
                <p>Track your orders on fingertips</p>
                <a href="https://play.google.com/store/apps/details?id=com.moglix.buyers" target="_blank" (click)="downloadMobileApp('googlePlay')"><img src="/assets/img/download-app/play_store/play_store.png"></a>
                <a href="https://apps.apple.com/in/app/moglix-for-enterprise/id1497681021" target="_blank" (click)="downloadMobileApp('appStore')"><img src="/assets/img/download-app/app_store/app_store.png"></a>
            </div>
        </li>
    </ul>
</div>