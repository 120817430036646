import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from "@angular/common/http"
import { Observable, throwError } from "rxjs"
import 'rxjs/add/operator/do'
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"

import { catchError, finalize, retry } from 'rxjs/operators';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { DataService } from "../../data.service"
import { environment } from "../../../../environments/environment"
declare var navigator;

// Sentry.init({
//     dsn: environment.sentry.DSN,
//     integrations: [
//         new Integrations.BrowserTracing({
//           tracingOrigins: [environment.sentry.ORIGIN],
//           routingInstrumentation: Sentry.routingInstrumentation,
//         }),
//       ],
//       // Set tracesSampleRate to 1.0 to capture 100%
//       // of transactions for performance monitoring.
//       // We recommend adjusting this value in production
//       tracesSampleRate: 1.0,
// });

@Injectable()

export class HttpInterceptorHandler implements HttpInterceptor {

    constructor(private router: Router, private _dataservice: DataService) {

        if(navigator?.connection?.rtt == 0 || navigator.connection?.rtt > 1800 ){
            this._dataservice.showMessage("error", 'Taking too long… Please check your internet connection!');
        }
        
        // when network status change
        navigator.connection.addEventListener('change', (e) => {
            if(e?.target?.rtt == 0 || e?.target?.rtt > 1800 ){
                this._dataservice.showMessage("error", 'Taking too long… Please check your internet connection!');
            }
        })
     }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            return next.handle(req)
            .pipe(
                retry(1),
                catchError((error) => {
                    // if(this._dataservice.userEmail){
                    //     Sentry.configureScope(scope => {
                    //         scope.setTag("email", this._dataservice.userEmail);
                    //         scope.setTag("company", this._dataservice.companyId);
                    //         scope.setTag("plant", this._dataservice.lastPlantId);
                    //         scope.setUser({
                    //             name: this._dataservice.userName,
                    //             email: this._dataservice.userEmail
                    //         });
                    //         //also add
                    //         Sentry.setContext("character", {
                    //           companyId: this._dataservice.companyId,
                    //           plantId: this._dataservice.lastPlantId
                    //         });
                    //       });
                    //     Sentry.captureException(error.originalError || error);
                    //   }
                     
                    if (error instanceof HttpErrorResponse) {
                        if (error.url.indexOf('/user/getSession') !== -1 && error.status == 400) {
                            this._dataservice.showMessage("error", "Session expired. Please login again. Redirecting...");
                            this._dataservice.removeLocalStorage();
                            this.router.navigateByUrl('/login');
                        }

                        let showErrMsg: string;
                        if (!error.ok) {
                            showErrMsg = "Internal Server Error Occured (" + error.status + "). Please refresh the page.";
                        }

                        this._dataservice.createOnline$().subscribe(isOnline => {
                            if(!isOnline){
                                showErrMsg = 'Taking too long… Please check your internet connection!';
                            }
                        });

                        if(showErrMsg){
                            $('#loader').css('display', 'none');
                            this._dataservice.showMessage("error", showErrMsg);
                        }

                        // Handle Http Error (error.status === 403, 404...)
                        console.log(`Handle Http Error ${error.status} - ${error.message}`);
                    } else {
                        // Handle Client Error (Angular Error, ReferenceError...)    
                        console.log(`Client Error ${error}`); 
                    }
                      return throwError(error);
                }),
                finalize(() => {
                    const newMessage = `${req.method} ${req.urlWithParams}`;
                    // console.log("finalize message", newMessage);
                })
            );
    }
}