<li>
    <div class="dropdown accountdrop">
        <button id="accountdrop" type="button" data-toggle="dropdown" (click) = "getSpendLimit()" aria-haspopup="true" aria-expanded="false">
         <span>{{userAcronym}}</span>
         <i *ngIf = "branchType != 'punchout'" class="ri-arrow-down-s-line"></i>
        </button>
        <ul *ngIf = "branchType != 'punchout'" class="dropdown-menu" aria-labelledby="accountdrop">
          <li><span>{{userName}}<br>
            <em>{{userEmail}}</em><br>
            <em *ngIf = "hasSpendLimit">Spend Limit: {{ spendLimit }}</em></span></li>
          <li routerLink="/pages/account"><span>My Account</span></li>
          <li (click) = onLogout()><span>Logout</span></li>
        </ul>
      </div>
</li>