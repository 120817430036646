import { Injectable } from '@angular/core';

export class Settings {
    constructor(
                public mmSearchValue:any,
                public selectedCompDetails:any
                ) { }
}

@Injectable()
export class AppSettings {
    public settings = new Settings(
        '',
        {}
    )
}