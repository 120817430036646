<!---header start-->
<header class="mogb_radio-fluid mogb_newheader">
    <div class="row clearfix">
        <div class="col-md-12 clearfix">
            <div class="mogb_leftheader">
                <button class="mogb_menubutton" (click)="toggleSidebar()">
                    <i class="ri-menu-line"></i>
                </button>
                <div class="mogb_logo">
                    <!-- <span><img src="assets/img/new-buyersportal/buyerlogo.png"></span> -->
                    <span (click) = "goToLandingPage()">
                      <img *ngIf = "activeBranch?.id !== '11115'" src="{{ vendorLogo }}" style="width: 160px;height:40px;">
                      <img *ngIf = "activeBranch?.id === '11115'" src="/assets/img/new-buyersportal/buyerlogo.png">
                    </span>
                    <span *ngIf='isSAPIntegrated || isSAPHybrid' class = "sap-img"><img src="{{ ( 'SAPintegrated' | baProfilePicture ) }}" ></span>
                    <!-- <span *ngIf='!isSAPIntegrated && isSAPHybrid' class = "sap-img"><img src="{{ ( 'SAPhybrid' | baProfilePicture ) }}"></span> -->
                </div>
                <div *ngIf = "showSearchBar" class="mogb_searchboxinline">
                  <div class="mogb_searchbox">
                      <input type="text" placeholder="Search product here…" [(ngModel)] = "selectedProduct" (keyup) = "searchProducts($event)" (blur) = "hideDropdown()">
                      <span class="searchicon" (click) = "searchProducts()"><i class="ri-search-line"></i></span>
                  </div>
                  <div class="mogb_searchlistwrap" *ngIf = "totalProducts > 0" (mousedown) = preventDefault($event)>
                      <span>Suggested Search</span>
                      <ul class="mogb_searchlist">
                          <li *ngFor = "let product of searchSuggessions" (click) = "selectProduct(product)"><span>{{ product }}</span></li>
                      </ul>
                  </div>
                </div>
                <!-- MM Search bar -->
                <div [ngClass]="{'hide': !showMMSearchBar}" class="mogb_searchboxinline">
                  <div class="mogb_searchbox">
                    <input placeholder="Search product here…" #MMinput [value]="settings.mmSearchValue">
                    <span class="searchicon"><i class="ri-search-line"></i></span>
                </div>
              </div>
            </div>


            <div class="mogb_rightheader widthGiftStore hide-rgt-nav">
              <a href="https://www.moglix.com/e-gift-voucher" target="_blank" class="giftStore" (click)="giftVoucher()">
                <img  src="/assets/img/gift_store.png">
              </a> 
                <ul class="rightheadermenulist">
                    <ba-cart-count *ngIf = "isPoWriteAccess" [plantId] = "selectedPlant"></ba-cart-count>
                    <!-- <li><div class="shopcart"><i class="ri-notification-3-fill"></i><span>30</span></div></li> -->
                    <li class="mogb_plantlist">
                        <div class="mogb_plantdropdown" data-toggle="modal" data-target="#plantselectpopup">
                            <span  *ngIf = "rightLogoImage === ''" >
                              <img src="assets/img/new-buyersportal/skycraper.svg">
                            </span >
                            <span class="vdlogo" *ngIf = "rightLogoImage != '' && activeBranch?.id !== '11115'"><img src="{{ rightLogoImage }}"></span>                       
                            <div class="mogb_dropplantname">
                              <span *ngIf = "activeBranch.id !== '-1'" title="{{ activeBranch.text }} ({{ activeBranch.id }})">{{ activeBranch.text }}&nbsp;&nbsp;({{ activeBranch.id }})</span>
                              <span *ngIf = "activeBranch.id === '-1'" title="{{ activeBranch.text }}">{{ activeBranch.text }}</span>
                              <i class="ri-arrow-down-s-line"></i></div>      
                        </div>
                    </li>
                    <ba-user-profile [branchType] = "branchType"></ba-user-profile>
                </ul>
            </div>


            <div class="dropdown show-rgt-nav">
              <button style="margin-top: 5px;margin-left: 15px;" class="btn btn-secondary mogb_rightheader dropdown-toggle" type="button" id="plantlistdrop" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="ri-menu-line"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="plantlistdrop">
                <div class="mogb_rightheader widthGiftStore">
                  <ul class="rightheadermenulist ul-fixed">
                    <ba-cart-count *ngIf="isPoWriteAccess" [plantId]="selectedPlant"></ba-cart-count>
                    <li class="mogb_plantlist">
                      <div class="mogb_plantdropdown" data-toggle="modal" data-target="#plantselectpopup">
                        <span *ngIf="rightLogoImage === ''">
                          <img src="assets/img/new-buyersportal/skycraper.svg">
                        </span>
                        <span class="vdlogo" *ngIf="rightLogoImage != '' && activeBranch?.id !== '11115'"><img
                            src="{{ rightLogoImage }}"></span>
                        <div class="mogb_dropplantname">
                          <span *ngIf="activeBranch.id !== '-1'" title="{{ activeBranch.text }} ({{ activeBranch.id }})">{{
                            activeBranch.text }}&nbsp;&nbsp;({{ activeBranch.id }})</span>
                          <span *ngIf="activeBranch.id === '-1'" title="{{ activeBranch.text }}">{{ activeBranch.text
                            }}</span>
                          <i class="ri-arrow-down-s-line"></i>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="mogb_rightheader widthGiftStore show-rgt-nav">
              <ul class="rightheadermenulist">
                <ba-user-profile [branchType] = "branchType"></ba-user-profile>
              </ul>
            </div>
            
        </div>
    </div>
</header>
<!---header end-->
<!---plant popup from header-->
<div class="modal fade" id="plantselectpopup" tabindex="'-1'" role="dialog" aria-labelledby="plantselectpopup" aria-hidden="true">
  <div class="modal-dialog plantpopupwrap" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Select Plant</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
      <div class="modal-body">
          <div class="mpgb_plantpopupinner">
              <div class="mogb_plantsearch">
                  <div class="mogb_searchbox">
                      <input type="text" placeholder="Search Company" [formControl]="searchCompanyInput" 
                      (keyup)="getSearchResults(searchCompanyInput, $event, 'compChange')">
                      <span class="searchicon" (click) = "getSearchResults(searchCompanyInput, $event, 'compChange')">
                        <i class="ri-search-line"></i></span>
                  </div>
              </div>
              <div class="mogb_plantsearch">
                  <div class="mogb_searchbox">
                      <input type="text" placeholder="Search Plant" [formControl]="searchPlantInput" 
                      (keyup)="getSearchResults(searchPlantInput, $event, 'plantChange')">
                      <span class="searchicon" (click) = "getSearchResults(searchPlantInput, $event, 'plantChange')">
                        <i class="ri-search-line"></i></span>
                  </div>
              </div>
          </div>  
          <div class="panelsaccordion">
          <div class="panel-group" id="mogb_accordion" role="tablist" aria-multiselectable="true">
              <div class="panel mogb_accpanel" *ngFor="let company of allCompaniesList; let i=index">
                <div class="panel-heading" role="tab" id="acc_1">
                    <a role="button" data-toggle="collapse" data-parent="#mogb_accordion" href="#acc_collpase{{i}}" aria-expanded="true" aria-controls="acc_collpase1">
                      {{company?.companyname}} ( {{company?.company}} )
                    </a>
                </div>
                <div id="acc_collpase{{i}}" class="panel-collapse collapse" [ngClass] = "{'in': company.company == selectedCompany || isExpand}" role="tabpanel" aria-labelledby="acc_1">
                  <div class="panel-body">
                      <ul>
                          <li *ngFor="let plants of company.plants let j=index;">
                            <label class="mogb_radio">{{ plants?.plantName }} <em *ngIf="plants.plantId !== '-1'">( {{plants?.plantId }} )</em>
                            <input type="radio" [checked] = "plants.plantId == selectedPlant && company.company === selectedCompany" name="acc1_radio"
                            (click)="changePlant(plants?.plantId, plants?.plantName, allCompaniesList[i])">
                            <span class="mogb_radio_checkmark"></span>
                            </label>
                          </li>
                      </ul>
                  </div>
                </div>
                <span class="collicon"></span>
              </div>
              
            </div>    
          </div>   
      </div>        
    </div>
  </div>
</div>
