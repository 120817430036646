import {Component, ViewEncapsulation, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';
import { BaMenuService } from '../../services';
import {GlobalState} from '../../../global.state';
import {DataService} from '../../../pages/data.service';
import { not } from '@angular/compiler/src/output/output_ast';
declare var $: any;
declare var dataLayer: any;

@Component({
    selector: 'ba-side-menu',
    templateUrl: 'baSideMenu.html',
    styleUrls: ['baSideMenu.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BaSideMenu {
  @Input() sidebarCollapsed:boolean = false;
  @Input() menuHeight:number;
  @Output() expandMenu = new EventEmitter<any>();

  public menuItems: any[];
  protected _menuItemsSub: Subscription;
  public showHoverElem: boolean;
  public hoverElemHeight: number;
  public hoverElemTop: number;
  protected _onRouteChange: Subscription;
  public outOfArea: number = -200;
  isMoglixCatalogSelected = false;

  constructor(private _router: Router, private _service: BaMenuService, private _state: GlobalState, private _data: DataService) {
    this._onRouteChange = this._router.events.subscribe((event) => {
      this.selectMenuAndNotify();
        //console.log('navigation event', event);
      // if (event instanceof NavigationEnd) {
      //   if (this.menuItems) {
      //     this.selectMenuAndNotify();
      //   } else {
      //     // on page load we have to wait as event is fired before menu elements are prepared
      //     setTimeout(() => this.selectMenuAndNotify());
      //   }
      // }
    });

    this._menuItemsSub = this._service.menuItems.subscribe(this.updateMenu.bind(this));
    // console.log(this.menuItems);
  }

  getActiveModule() {
    for (let i = 0; i < this.menuItems.length; i++) {
      if(this.menuItems[i].children && this.menuItems[i].expanded){
        for (let j = 0; j < this.menuItems[i].children.length; j++) {
          if (this.menuItems[i].children[j].selected) {
            this._data.setSelectedModule(this.menuItems[i].children[j].module);
          }
        }
      } else if(!this.menuItems[i].children && this.menuItems[i].selected){
        this._data.setSelectedModule(this.menuItems[i].module);
      }
    }
  }

  public updateMenu(newMenuItems) {
    this.menuItems = newMenuItems;
   // console.log('menuItems', this.menuItems);
    this.getActiveModule();
    this.selectMenuAndNotify();
  }

  public selectMenuAndNotify(): void {
    //console.log('menu items', this.menuItems)
    if (this.menuItems) {
      this.menuItems = this._service.selectMenuItem(this.menuItems);
      this._state.notifyDataChanged('menu.activeLink', this._service.getCurrentItem());
    }
  }
  public ngOnDestroy(): void {
    this._onRouteChange.unsubscribe();
    this._menuItemsSub.unsubscribe();
  }

  public hoverItem($event):void {
  //  console.log('hoverItem', $event);
    this.showHoverElem = true;
    this.hoverElemHeight = $event.currentTarget.clientHeight;
    // TODO: get rid of magic 66 constant
    this.hoverElemTop = $event.currentTarget.getBoundingClientRect().top - 66;
  }

  public toggleSubMenu($event):boolean {
    this.closeAllItem(); 
    if (this.sidebarCollapsed) {
      this.expandMenu.emit(null);
    }
    this.updateCollapseState($event);
    return false;
  }

  closeAllItem() {
    this.menuItems.forEach((item, index) => {
      this.menuItems[index]['expanded'] = false;
    });
    $('.showlist').slideUp(400);
  }

  updateCollapseState($event){
    var submenu = jQuery($event.currentTarget).next();
    const itemdata = $event.item
    this.menuItems.forEach((item, index) => {
     if(itemdata.title == item.title){
      //  console.log('itemdata', itemdata);
       this.menuItems[index]['expanded'] = !this.menuItems[index]['expanded'];
       if(this.menuItems[index]['expanded']){
        submenu.slideToggle();
       }
       
     }
    });
  }
  ngAfterViewInit(){
    
  }

  downloadMobileApp(name){
    if(name == 'googlePlay'){
      dataLayer.push({ 'event': 'downloadMobileApp', 'downloadMobileApp': 'Google Play' });
    }else{
      dataLayer.push({ 'event': 'downloadMobileApp', 'downloadMobileApp': 'App Store' });
    }
  }

}
