
  <div class="mogb_page">
    <ba-header></ba-header>
    <div class="mogb_mainwrap sidebaractive">
      <aside class="mgb_sidebar active">
            <ba-sidebar></ba-sidebar>
      </aside>
      <div class="mogb_pagearea">
        <router-outlet></router-outlet>
      </div>
    </div>
    <ba-back-top position="200"></ba-back-top>
  </div>
  