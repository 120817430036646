import { Routes, RouterModule } from '@angular/router';
import { Pages } from './pages.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from "./auth.guard";
import { AccessableGuard } from "./accessable.guard";
import { HideLoaderGuard } from "./hideLoader.guard";
import { ServiceDisableGuard } from "./serviceDisable.guard";
import { AppredirectComponent } from './appredirect/appredirect.component';

export const routes: Routes = [
    {
      path: '',
      loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    },
    {
      path: 'login',
      loadChildren: () => import('./new-login/new-login.module').then(m => m.NewLoginModule),
      canActivate:[HideLoaderGuard]
    },
    {
      path: 'logout',
      loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule),
      canActivate:[HideLoaderGuard]
    },
    {
      path: 'register',
      loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
      canActivate:[HideLoaderGuard]
    },
    {
      path: 'reset',
      loadChildren: () => import('./reset/reset.module').then(m => m.ResetModule),
      canActivate:[HideLoaderGuard]
    },
    {
      path: 'forget',
      loadChildren: () => import('./forget/forget.module').then(m => m.ForgetModule),
      canActivate:[HideLoaderGuard]
    },
    {
      path: 'um',
      loadChildren: () => import('./um/um.module').then(m => m.UserManagementModule),
      canActivate:[AccessableGuard,AuthGuard]
    },
    {
      path: 'page/:poid/:cid/:pid',
      component: AppredirectComponent
    },
    {
      path: 'pages',
      component: Pages,
      children: [
        { path: '', redirectTo: 'orders', pathMatch: 'full' },
        // { path: 'dashboard', loadChildren: './db/db.module#DbModule', canActivate:[AccessableGuard]},
        // { path: 'pr',loadChildren: () => import('./pr/pr.module').then(m => m.PrsModule), canActivate:[AccessableGuard]},
        { path: 'pr',loadChildren: () => import('./purchaseRequest/pr.module').then(m => m.PRModule), canActivate:[AccessableGuard]},
        { path: 'rfq',loadChildren: () => import('./rfq/rfq.module').then(m => m.RfqModule), canActivate:[AccessableGuard] },
        { path: 'orders',loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)},
        { path: 'inv',loadChildren: () => import('./shipment/shipment.module').then(m => m.ShipmentModule)},
        { path: 'grn',loadChildren: () => import('./grnv2/grn.module').then(m => m.GrnModule)},
        { path: 'pm',loadChildren: () => import('./myCatalog/myCatalog.module').then(m => m.MyCatalogModule)},
        { path: 'pmt',loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)},
        { path: 'um2',loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule) },
        { path: 'um',loadChildren: () => import('./um/um.module').then(m => m.UserManagementModule) },
        { path: 'sm',loadChildren: () => import('./vendors/vendors.module').then(m => m.VendorsModule), canActivate:[AccessableGuard] },
        { path: 'catalog', loadChildren: () => import('./catelog/catelog.module').then(m => m.CatelogModule)},
        { path: 'mm-catalog', loadChildren: () => import('./mmCatalog/mmCatalog.module').then(m => m.MmCatalogModule)},
        { path: 'catalogue', loadChildren: () => import('./catalogue/catalogue.module').then(m => m.CatalogueModule)},
        { path: 'account', loadChildren: () => import('./myaccount/myaccount.module').then(m => m.MyaccountModule)},
        { path: 'bi', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
        { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule)},
        { path: 'projects', loadChildren: () => import('./maasProject/maasProject.module').then(m => m.MaasProjectModule)},
        //{ path: 'user-management',loadChildren: './um/user-management.module#UserManagementModule' },
        //{ path: 'plots' , loadChildren: './plots/plots.module#PlotsModule'}
      ],
        canActivate: [AuthGuard]
    }
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
