export const PAGES_MENU = [
  {
    path: 'pages',
    children: [
      {
        path: 'bi',
        data: {
          menu: {
            title: 'Dashboard',
            module: 'BI',
            icon: 'ri-dashboard-fill',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'projects',
        data: {
          menu: {
            title: 'Projects',
            module: 'PO',
            icon: 'ri-profile-fill',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: '',
        data: {
          menu: {
            title: 'Procurement',
            icon: 'ri-shopping-cart-2-fill',
            selected: false,
            expanded: false,
            order: 200,
          }
        },
        children: [
          {
            path: 'pr',
            data: {
              menu: {
                title: 'PR',
                module: 'PR',
                icon: 'pr',
                selected: false,
                expanded: false,
                order: 0
              }
            }
          },
          {
            path: 'rfq',
            data: {
              menu: {
                title: 'RFQ',
                module: 'RFQ',
                icon: 'rfq',
                selected: false,
                expanded: false,
                order: 100,
              }
            }
          },
          {
            path: 'orders',
            data: {
              menu: {
                title: 'Orders',
                module: 'PO',
                icon: 'po',
                selected: false,
                expanded: false,
                order: 200,
              }
            }
          },
          {
            path: 'inv',
            data: {
              menu: {
                title: 'Shipment',
                module: 'INV',
                icon: 'inv',
                selected: false,
                expanded: false,
                order: 200,
              }
            }
          },
          {
            path: 'grn',
            data: {
              menu: {
                title: 'GRN',
                module: 'GRN',
                icon: 'grn',
                selected: false,
                expanded: false,
                order: 200,
              }
            }
          },
          {
            path: 'pmt',
            data: {
              menu: {
                title: 'Payment',
                module: 'PMT',
                icon: 'pmt',
                selected: false,
                expanded: false,
                order: 500,
              }
            }
          },
          {
            path: 'ra',
            data: {
              menu: {
                title: 'Auction',
                icon: 'auction',
                selected: false,
                expanded: false,
                order: 500,
              }
            }
          }
        ]
      },
      {
        path: 'catelog',
        data: {
          menu: {
            title: 'Moglix Catelog',
            icon: 'store-inventory',
            selected: false,
            expanded: false,
            order: 800,
          }
        }
      }

    ]
  },
  {
    path: '',
    data: {
      menu: {
        title: 'Catalog',
        icon: 'ri-profile-fill',
        selected: false,
        expanded: false,
        order: 200,
      }
    },
    children: [
      {
        path: ['/', 'pages', 'catalogue', 'all-products'],
        data: {
          menu: {
            icon: 'vendor',
            title: 'ARC Catalog',
            module: 'TC'
          }
        }
      },
      {
        path: ['/', 'pages', 'mm-catalog', 'home-category'],
        data: {
          menu: {
            icon: 'vendor',
            title: 'RFQ Catalog',
            module: 'MM'
          }
        }
      },
    ]
  },
  {
    path: '',
    data: {
      menu: {
        title: 'Master Data',
        module: 'MD',
        icon: 'ri-stack-fill',
        selected: false,
        expanded: false,
        order: 650,
      }
    },
    children: [
      // {
      //   path: ['/','pages','sm'],
      //   data: {
      //     menu: {
      //       icon: 'vendor',
      //       title: 'Vendors',
      //       module: 'SM'
      //     }
      //   }
      // },
      {
        path: ['/', 'pages', 'pm', 'product-list'],
        data: {
          menu: {
            icon: 'pm',
            title: 'My Catalog',
            module: 'PM'
          }
        }
      }
    ]
  },
  {
    path: '',
    data: {
      menu: {
        title: 'Manage',
        icon: 'ri-shield-user-fill',
        selected: false,
        expanded: false,
        order: 650,
      }
    },
    children: [
      {
        path: ['/', 'pages', 'account'],
        data: {
          menu: {
            icon: 'my-account',
            title: 'My Account',
            module: 'UM'
          }
        }
      },
      {
        path: ['/', 'pages', 'um2', 'users'],
        data: {
          menu: {
            title: 'Users',
            module: 'UM',
            icon: 'user',
            selected: false,
            expanded: false
          }
        }
      },
      {
        path: ['/', 'pages', 'um2', 'roles'],
        data: {
          menu: {
            title: 'Roles',
            module: 'UM',
            icon: 'roles',
            selected: false,
            expanded: false
          }
        }
      },
      {
        path: ['/', 'pages', 'um2', 'plants'],
        data: {
          menu: {
            title: 'Plants',
            module: 'UM',
            icon: 'plants',
            selected: false,
            expanded: false
          }
        }
      },
      {
        path: ['/', 'pages', 'um2', 'company'],
        data: {
          menu: {
            icon: 'pm',
            title: 'Company',
            module: 'UM',
            selected: false,
            expanded: false
          }
        }
      }
      // ,
      // {
      //   path: ['/','pages','um','config'],
      //   data: {
      //     menu: {
      //       title: 'config',
      //       icon: 'user',
      //       selected: false,
      //       expanded: false
      //     }
      //   }
      // }
    ]
  },
  {
    path: '',
    data: {
      menu: {
        title: 'Manage',
        icon: 'ri-shield-user-fill',
        selected: false,
        expanded: false,
        order: 650,
      }
    },
    children: [
      {
        path: ['/', 'pages', 'account'],
        data: {
          menu: {
            icon: 'my-account',
            title: 'My Account',
            module: 'UM'
          }
        }
      },
      {
        path: ['/', 'pages', 'um', 'users'],
        data: {
          menu: {
            title: 'Users',
            module: 'UM',
            icon: 'user',
            selected: false,
            expanded: false
          }
        }
      },
      {
        path: ['/', 'pages', 'um', 'roles'],
        data: {
          menu: {
            title: 'Roles',
            module: 'UM',
            icon: 'roles',
            selected: false,
            expanded: false
          }
        }
      },
      {
        path: ['/', 'pages', 'um', 'plants'],
        data: {
          menu: {
            title: 'Plants',
            module: 'UM',
            icon: 'plants',
            selected: false,
            expanded: false
          }
        }
      },
      {
        path: ['/', 'pages', 'um', 'company'],
        data: {
          menu: {
            icon: 'pm',
            title: 'Company',
            module: 'UM',
            selected: false,
            expanded: false
          }
        }
      }
    ]
  },
  {
    path: ['/', 'pages', 'catalog', 'brands'],
    data: {
      menu: {
        title: 'Moglix Catalog',
        module: 'MC',
        icon: 'ri-dashboard-fill',
        selected: false,
        expanded: false,
        order: 0
      }
    }
  },
  {
    path: '',
    data: {
      menu: {
        title: 'Support',
        module: 'SUP',
        icon: 'ri-stack-fill',
        selected: false,
        expanded: false,
        order: 650,
      }
    },
    children: [
      {
        path: ['/', 'pages', 'support', 'approval'],
        data: {
          menu: {
            icon: 'vendor',
            title: 'Approval',
            module: 'SUP'
          }
        }
      },
      {
        path: ['/', 'pages', 'support', 'cm'],
        data: {
          menu: {
            icon: 'pm',
            title: 'Catalog Management',
            module: 'SUP'
          }
        }
      },
      {
        path: ['/', 'pages', 'support', 'upload-sa'],
        data: {
          menu: {
            icon: 'pm',
            title: 'Upload SA',
            module: 'SUP'
          }
        }
      }
    ]
  },
  {
    path: '',
    data: {
      menu: {
        title: 'Gift Store',
        icon: 'ri-gift-fill',
        selected: false,
        expanded: false,
        order: 650,
        class: 'abc'
      }
    },
    children: [
      {
        data: {
          menu: {
            module: 'PO',
            title: 'e-Gift Card',
            href: 'https://www.moglix.com/e-gift-voucher',
            target: '_blank'
          }
        }
      },
    ]
  }
];
